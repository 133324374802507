import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Badge,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Spinner,
} from 'reactstrap';
import UnitGrid from './UnitGrid';
import FloorPlanGrid from './FloorPlanGrid';
import { ToggleButton } from '@sparklib';
import { toast } from 'react-toastify';
import {
  PROPERTIES,
  UNIT_ADDONS,
  UNIT_MULTI_CREATE,
  UNIT_MULTI_UPDATE,
  UNIT_MULTI_DELETE,
  UNIT_FEATURES, // for floor plan unit features
  FLOORPLAN_MULTI_CREATE,
  FLOORPLAN_MULTI_UPDATE,
  FLOORPLAN_MULTI_DELETE,
	UPDATE_FLOORPLANS_ON_UNIT_CHANGE
} from '@api';

import {
  useQuery,
  useMutation,
  // useLazyQuery,
  // refetchQueries,
} from '@apollo/client';

export default function PropertyUnitsManager({
  showUnitsManager,
  setShowUnitsManager,
  reloadProperties,
}) {
  // Active Grid
  const [isUnitGridActive, setIsUnitGridActive] = useState(true);
  // Property units
  const [propertyUnits, setPropertyUnits] = useState([]);
  // Property floor plans
  const [propertyFloorPlans, setPropertyFloorPlans] = useState([]);
  // Is data (units/typedetails) changed, new row added, deleted existing row
  // or made any changes?
  const [isChanged, setIsChanged] = useState(false);
  // Saving in progress
  const [isProgress, setIsProgress] = useState(false);
  // Is there any error saving the units (create, update, or delete)
  const [isError, setIsError] = useState(false);
  // Actual error messages
  const [error, setError] = useState([]);
  // Prevent accidental close of units modal, by escape or close button and losing changes
  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  // Just save OR save & close?
  const [saveAndClose, setSaveAndClose] = useState({
    save: false,
    close: false,
  });
  const [addons, setAddons] = useState([{}]);
  const [newlyCreatedFloorPlans, setNewlyCreatedFloorPlans] = useState([]);
  // let newlyCreatedFloorPlans = [];

	const [updateFloorPlansOnUnitsChange] = useMutation(UPDATE_FLOORPLANS_ON_UNIT_CHANGE);

  const checkForErrors = (errorObject) => {
    if (errorObject) {
      setIsError(true);
      setError([...error, ...errorObject]);
    }
  };

  const { data: addonsData } = useQuery(UNIT_ADDONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      console.log(data, '--- unit addons');
      setAddons(data.unitAddons);
    },
  });

  const [unitMultiCreate, { error: createUnitsError }] = useMutation(
    UNIT_MULTI_CREATE,
    {
      onCompleted: (data) => {
        checkForErrors(data.unitMultiCreate.errors);
      },
      refetchQueries: [UNIT_ADDONS],
      onError: (e) => {
        toast(e, { type: 'error' });
        console.log(e);
        checkForErrors([e]);
      },
    }
  );

  const [unitMultiUpdate, { error: updateUnitsError }] = useMutation(
    UNIT_MULTI_UPDATE,
    {
      onCompleted: (data) => {
        checkForErrors(data.unitMultiUpdate.errors);
      },
      refetchQueries: [UNIT_ADDONS],
      onError: (e) => {
        toast(e, { type: 'error' });
        console.log(e);
        checkForErrors([e]);
      },
    }
  );

  const [unitMultiDelete, { error: deleteUnitsError }] = useMutation(
    UNIT_MULTI_DELETE,
    {
      onCompleted: (data) => {
        checkForErrors(data.unitMultiDelete.errors);
        // setShowDeleteConfirmation(false);
        // toast(data.unitMultiDelete.message, { type: 'success' });
        // setUnits((units) => units.filter((unit) => unit.id !== deleteUnits[0].id))
        // setProperties(
        //   _.cloneDeep(properties).filter((r) => r.id !== deleteProperties[0].id)
        // );
        // setIsProgress(false);
      },
      // refetchQueries: [PROPERTIES],
      onError: (e) => {
        // setShowDeleteConfirmation(false);
        toast(e, { type: 'error' });
        console.log(e);
        checkForErrors([e]);
      },
    }
  );

  const [floorPlanMultiCreate, { error: createFloorPlansError }] = useMutation(
    FLOORPLAN_MULTI_CREATE,
    {
      onCompleted: (data) => {
        checkForErrors(data.floorPlanMultiCreate.errors);
        // console.log(data.floorPlanMultiCreate, 'data.floorPlanMultiCreate');
        // newlyCreatedFloorPlans = data.floorPlanMultiCreate.floorPlans;
        setNewlyCreatedFloorPlans(data.floorPlanMultiCreate.floorPlans);
        console.log(newlyCreatedFloorPlans, 'newlyCreatedFloorPlans');
      },
      refetchQueries: [UNIT_FEATURES],
      onError: (e) => {
        toast(e, { type: 'error' });
        console.log(e);
        checkForErrors([e]);
      },
    }
  );

  const [floorPlanMultiUpdate, { error: updateFloorPlansError }] = useMutation(
    FLOORPLAN_MULTI_UPDATE,
    {
      onCompleted: (data) => {
        checkForErrors(data.floorPlanMultiUpdate.errors);
      },
      refetchQueries: [UNIT_FEATURES],
      onError: (e) => {
        toast(e, { type: 'error' });
        console.log(e);
        checkForErrors([e]);
      },
    }
  );

  const [floorPlanMultiDelete, { error: deleteFloorPlansError }] = useMutation(
    FLOORPLAN_MULTI_DELETE,
    {
      onCompleted: (data) => {
        checkForErrors(data.floorPlanMultiDelete.errors);
        // setShowDeleteConfirmation(false);
        // toast(data.unitMultiDelete.message, { type: 'success' });
        // setUnits((units) => units.filter((unit) => unit.id !== deleteUnits[0].id))
        // setProperties(
        //   _.cloneDeep(properties).filter((r) => r.id !== deleteProperties[0].id)
        // );
        // setIsProgress(false);
      },
      // refetchQueries: [PROPERTIES],
      onError: (e) => {
        // setShowDeleteConfirmation(false);
        toast(e, { type: 'error' });
        console.log(e);
        checkForErrors([e]);
      },
    }
  );

  const resetUnitsManager = () => {
    setError([]);
    setIsError(false);
    setShowUnitsManager({ show: false, property: null });
  };

  const closeUnitsManager = () => {
    isChanged ? setShowUnsavedConfirmation(true) : resetUnitsManager();
  };

  function currentPropertyUnits() {
    return propertyUnits.filter(
      (unit) => unit.type !== 'NEW' && unit.type !== 'DELETED'
    );
  }

  function currentPropertyFloorPlans() {
    return propertyFloorPlans.filter(
      (floorPlan) => floorPlan.type !== 'NEW' && floorPlan.type !== 'DELETED'
    );
  }

  function currentPropertyUnitCount() {
    return currentPropertyUnits().length;
  }

  function currentPropertyFloorPlanCount() {
    return currentPropertyFloorPlans().length;
  }

  function currentPropertyUnitsForCacheUpdate() {
    // console.log(currentPropertyUnits(), '---- currentPropertyUnits()');
    const onlyUpdatedUnits = currentPropertyUnits().filter(
      (unit) => unit.id !== undefined && unit.type !== 'CREATE'
    );
    // console.log(onlyUpdatedUnits, '---- onlyUpdatedUnits');
    return onlyUpdatedUnits.map((unit) => {
      delete unit.type;
      // console.log(unit, 'unit destructure');
      return {
        __typename: 'TypeDetail',
        ...unit,
        floorPlanId: Number(unit.floorPlanId),
        bath: Number(unit.bath),
        price: Number(unit.price.toString().replace(/,/g, '')),
        size: Number(unit.size),
      };
    });
  }

  function currentPropertyFloorPlansForCacheUpdate() {
    // console.log(currentPropertyUnits(), '---- currentPropertyUnits()');
    const onlyUpdatedFloorPlans = currentPropertyFloorPlans().filter(
      (floorPlan) => floorPlan.id !== undefined && floorPlan.type !== 'CREATE'
    );
    // console.log(onlyUpdatedUnits, '---- onlyUpdatedUnits');
    return onlyUpdatedFloorPlans.map((floorPlan) => {
      // console.log(floorPlan, 'floorPlan destructure');
      delete floorPlan.type;
      return {
        __typename: 'FloorPlan',
        ...floorPlan,
        // floorPlanId: Number(floorPlan.floorPlanId),
        bath: Number(floorPlan.bath),
        // price: Number(floorPlan.price),
        // size: Number(floorPlan.size),
      };
    });
  }

  useEffect(() => {
    // console.log('SHOW ERRORRRRRRS OUTSIDE ');
    if (isProgress === false && saveAndClose.save) {
      // console.log(error.length, 'SHOW ERRORRRRRRS');
      if (isError) {
        toast(`There were ${error.length} errors, please try again.`, {
          type: 'error',
        });
      } else {
        setIsChanged(false);
        reloadProperties();
        toast('Unit changes saved successfully', { type: 'success' });
        // saveAndClose.close ? () => {setSaveAndClose({save: false, close: false}); resetUnitsManager()} : setSaveAndClose({save: false, close: false});
        saveAndClose.close && resetUnitsManager();
        setSaveAndClose({ save: false, close: false });
      }
    }
  }, [isProgress]);

  function startSave(saveAndCloseOption) {
    setError([]);
    setIsError(false);
    setIsProgress(true);
    setSaveAndClose(saveAndCloseOption);
  }

  useEffect(async () => {
    if (saveAndClose.save) {
      let newFloorPlans,
        updatedFloorPlans,
        deletedFloorPlans,
        newUnits,
        updatedUnits,
        deletedUnits = [];
      let propertyId = Number(showUnitsManager.property.id);

      function preparePropertyFloorPlans() {
        const copiedPropertyFloorPlans = _.cloneDeep(propertyFloorPlans);

        newFloorPlans = copiedPropertyFloorPlans.filter(
          (floorPlan) => floorPlan.type === 'CREATE'
        );
        newFloorPlans = newFloorPlans.map((floorPlan) => {
          delete floorPlan.type;
          delete floorPlan.id;
          return {
            ...floorPlan,
            bath: Number(floorPlan.bath),
            // price: Number(floorPlan.price),
            // size: Number(floorPlan.size),
            propertyId: propertyId,
          };
        });
        updatedFloorPlans = copiedPropertyFloorPlans.filter(
          (floorPlan) => floorPlan.type === 'CHANGED'
        );
        updatedFloorPlans = updatedFloorPlans.map((floorPlan) => {
          delete floorPlan.type;
          delete floorPlan.updatedAt;
          delete floorPlan.__typename;
          delete floorPlan.imageUrls;
          delete floorPlan.imageIds;
          delete floorPlan.plan2d;
          delete floorPlan.plan3d;
          return {
            ...floorPlan,
            id: Number(floorPlan.id),
            bath: Number(floorPlan.bath),
            // price: Number(floorPlan.price),
            // size: Number(floorPlan.size),
            propertyId: propertyId,
          };
        });
        deletedFloorPlans = copiedPropertyFloorPlans.filter(
          (floorPlan) => floorPlan.type === 'DELETED'
        );
        deletedFloorPlans = deletedFloorPlans.map((floorPlan) => {
          return Number(floorPlan.id);
        });
      }

      function preparePropertyUnits() {
        const copiedPropertyUnits = _.cloneDeep(propertyUnits);

        newUnits = copiedPropertyUnits.filter((unit) => unit.type === 'CREATE');
        newUnits = newUnits.map((unit) => {
          if (Number(unit.floorPlanId) < 0) {
            // The unit being added is under newly created floor plan
            console.log(
              'NEW FLOORPLAN',
              unit.floorPlan,
              newlyCreatedFloorPlans
            );
            unit.floorPlanId = newlyCreatedFloorPlans.find(
              (floorPlan) => floorPlan.name === unit.floorPlan.name
            )?.id;
          }
          delete unit.type;
          delete unit.id;
          delete unit.floorPlan;
          return {
            ...unit,
            // bath: Number(unit.bath),
            price: Number(unit.price.toString().replace(/,/g, '')),
            size: Number(unit.size),
            floorPlanId: Number(unit.floorPlanId),
            propertyId: propertyId,
          };
        });
        updatedUnits = copiedPropertyUnits.filter(
          (unit) => unit.type === 'CHANGED'
        );
        updatedUnits = updatedUnits.map((unit) => {
          delete unit.type;
          delete unit.floorPlan;
          delete unit.updatedAt;
          delete unit.__typename;
          return {
            ...unit,
            id: Number(unit.id),
            // bath: Number(unit.bath),
            price: Number(unit.price.toString().replace(/,/g, '')),
            size: Number(unit.size),
            floorPlanId: Number(unit.floorPlanId),
            propertyId: propertyId,
          };
        });
        deletedUnits = copiedPropertyUnits.filter(
          (unit) => unit.type === 'DELETED'
        );
        deletedUnits = deletedUnits.map((unit) => {
          return Number(unit.id);
        });
      }

      // SAVE FLOORPLANS DATA TO BACKEND
      preparePropertyFloorPlans();

      await floorPlanMultiDelete({
        variables: {
          ids: deletedFloorPlans,
        },
      });
      await floorPlanMultiUpdate({
        variables: {
          updateFloorPlans: updatedFloorPlans,
        },
      });
      await floorPlanMultiCreate({
        variables: {
          createFloorPlans: newFloorPlans,
        },
        // update: (cache, data) => {
        //   // console.log(data, 'FLOOR PLAN DATA FOR UPDATE');
        //   const { properties } = cache.readQuery({ query: PROPERTIES });
        //   const propertyId = showUnitsManager.property.id;
        //   const mergedFloorPlans = [
        //     ...currentPropertyFloorPlansForCacheUpdate(),
        //     ...data.data.floorPlanMultiCreate.floorPlans,
        //   ];
        //   const updatedProperties = properties.edges.map((property) =>
        //     property.node.id === propertyId
        //       ? {
        //           ...property,
        //           node: { ...property.node, floorPlans: mergedFloorPlans },
        //         }
        //       : property
        //   );
        //   const updatedPropertiesConnection = {
        //     ...properties,
        //     edges: updatedProperties,
        //   };
        //   cache.writeQuery({
        //     query: PROPERTIES,
        //     data: { properties: updatedPropertiesConnection },
        //   });
        //   // }
        // },
      });

      // SAVE UNITS DATA TO BACKEND
      preparePropertyUnits();
      // console.log(newUnits, '---- ready to be created');
      // console.log(updatedUnits, '---- ready to be updated');
      // console.log(deletedUnits, '---- ready to be deleted');

      await unitMultiDelete({
        variables: {
          ids: deletedUnits,
        },
      });
      await unitMultiUpdate({
        variables: {
          updateUnits: updatedUnits,
        },
      });
      await unitMultiCreate({
        variables: {
          createUnits: newUnits,
        },
        // update: (cache, data) => {
        //   // if (!createError && !updateError && !deleteError) {
        //   // console.log(data.data.unitMultiCreate, '---- new units');
        //   const { properties } = cache.readQuery({ query: PROPERTIES });
        //   const propertyId = showUnitsManager.property.id;
        //   // console.log(currentPropertyUnitsForCacheUpdate(), 'currentPropertyUnitsForCacheUpdate');
        //   const mergedUnits = [
        //     ...currentPropertyUnitsForCacheUpdate(),
        //     ...data.data.unitMultiCreate.units,
        //   ];
        //   // console.log(mergedUnits, '---- NEW UNITS');
        //   const updatedProperties = properties.edges.map((property) =>
        //     property.node.id === propertyId
        //       ? {
        //           ...property,
        //           node: { ...property.node, typeDetails: mergedUnits },
        //         }
        //       : property
        //   );
        //   const updatedPropertiesConnection = {
        //     ...properties,
        //     edges: updatedProperties,
        //   };
        //   // console.log(updatedPropertiesConnection, '----- UPDATED PROPERTIES');
        //   cache.writeQuery({
        //     query: PROPERTIES,
        //     data: { properties: updatedPropertiesConnection },
        //   });
        //   // }
        // },
      });

			await updateFloorPlansOnUnitsChange({
				variables: {
					propertyId: Number(showUnitsManager.property.id),
					forAvailableUnitsOnly: true
				}
			});

      setIsProgress(false);
    }
  }, [saveAndClose]);

	function setup() {
		let tmpUnits = showUnitsManager.property?.typeDetails
			?.map((unit) => {
				return { type: 'UNCHANGED', ...unit, floorPlanId: unit.floorPlan.id };
			})
			.concat([
				{
					type: 'NEW',
					id: '-1100',
					isAvailable: false,
					floorPlan: {},
					floorPlanId: '-1',
					addonItems: [],
				},
			]);
		setPropertyUnits(tmpUnits);

		let tmpFloorPlans = showUnitsManager.property?.floorPlans
			?.map((floorPlan) => {
				return { type: 'UNCHANGED', ...floorPlan };
			})
			.concat([{ type: 'NEW', id: '-1100', unitFeatures: [] }]);
		setPropertyFloorPlans(tmpFloorPlans);
	}
	function cleanup() {
		setIsChanged(false);
		setPropertyUnits([]);
		setPropertyFloorPlans([]);
		setIsUnitGridActive(true);
	}

  useEffect(() => {
    showUnitsManager.show === true ? setup() : cleanup();
  }, [showUnitsManager]);

  return (
    <>
      {/* {isProgress && <Spinner />} */}
      {/* Delete Confirmation */}
      <Modal
        isOpen={showUnsavedConfirmation}
        toggle={(event) => {
          if (event.key === 'Escape') {
            setShowUnsavedConfirmation(!showUnsavedConfirmation);
          }
        }}
      >
        {/* toggle={(e) => setShowUnsavedConfirmation(!showUnsavedConfirmation)}> */}
        <ModalHeader>Unsaved Changes</ModalHeader>
        <ModalBody>
          You have unsaved changes. Are you sure you want to close?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setShowUnsavedConfirmation(false);
              resetUnitsManager();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setShowUnsavedConfirmation(!showUnsavedConfirmation)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        // container={document.getElementById('root')}
        className='modal-dialog-centered big-modal'
        key={showUnitsManager.property?.id}
        isOpen={showUnitsManager.show}
        toggle={(event) => {
          if (event.key === 'Escape') {
            closeUnitsManager();
          }
        }}
      >
        <ModalHeader>
          <Col md='8' className='px-0 d-inline-flex align-items-center'>
            {showUnitsManager.property?.name}
            <Badge
              color={isUnitGridActive ? 'blue' : 'green'}
              pill
              className='ml-2'
            >
              {/* <small> */}
              <strong>
                {isUnitGridActive ? (
                  <>
                    {currentPropertyUnitCount()}
                    {currentPropertyUnitCount() === 1 ? ' unit' : ' units'}
                  </>
                ) : (
                  <>
                    {currentPropertyFloorPlanCount()}
                    {currentPropertyFloorPlanCount() === 1
                      ? ' floor plan'
                      : ' floor plans'}
                  </>
                )}
              </strong>
              {/* </small> */}
            </Badge>
          </Col>
          <Col
            md='4'
            className='px-0 d-inline-flex align-items-center justify-content-end properties-toggle-switch'
          >
            <ToggleButton
              selected={isUnitGridActive}
              firstOption='Units'
              secondOption='Floor Plans'
              width='160px'
              rounded
              toggleSelected={() => {
                setIsUnitGridActive(!isUnitGridActive);
              }}
            />
          </Col>
        </ModalHeader>
        <ModalBody>
          <Col md='12' className='property-grid p-0'>
            {isUnitGridActive ? (
              <UnitGrid
                key={showUnitsManager.property && showUnitsManager.property.id}
                propertyName={showUnitsManager.property?.name}
                units={propertyUnits}
                setUnits={setPropertyUnits}
                floorPlans={propertyFloorPlans}
                setIsChanged={setIsChanged}
                addons={addons}
              />
            ) : (
              <FloorPlanGrid
                key={showUnitsManager.property && showUnitsManager.property.id}
                propertyName={showUnitsManager.property?.name}
                floorPlans={propertyFloorPlans}
                setFloorPlans={setPropertyFloorPlans}
                units={propertyUnits}
                setUnits={setPropertyUnits}
                setIsChanged={setIsChanged}
                addons={addons}
                setAddons={setAddons}
								reloadProperties={reloadProperties}
								propertyDetails={showUnitsManager.property}
              />
            )}
          </Col>
        </ModalBody>
        <ModalFooter>
          <div className='d-flex' style={{ flex: 'auto' }}>
            <small>
              {isUnitGridActive ? (
                <>
                  Showing {currentPropertyUnitCount()}
                  {currentPropertyUnitCount() === 1
                    ? ' unit '
                    : ' units '} at {showUnitsManager.property?.name}
                </>
              ) : (
                <>
                  Showing {currentPropertyFloorPlanCount()}
                  {currentPropertyFloorPlanCount() === 1
                    ? ' floor plan '
                    : ' floor plans '}
                  at {showUnitsManager.property?.name}
                </>
              )}
            </small>
          </div>
          <Button
            color='blue'
            disabled={!isChanged || isProgress}
            onClick={() => startSave({ save: true, close: false })}
            type='submit'
          >
            Save
          </Button>
          <Button
            color='green'
            disabled={!isChanged || isProgress}
            onClick={() => startSave({ save: true, close: true })}
            type='submit'
          >
            Save & Close
          </Button>
          <Button disabled={isProgress} onClick={() => closeUnitsManager()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
