import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
	Badge
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';
import config from '@config';
import RTable from '../RTable';
import { GUEST_REPORTS, REPORTS_MULTI_DELETE, RESEND_REPORT, UPDATE_REPORT_TITLE } from '@api';
import { Icon } from '@assets';
import SendDraftReport from '../../modules/Property/Properties/SendDraftReport';
import SidebarRight from '../SidebarRight';

export default function MyReport(props) {
  
  const [reports, setReports] = useState([]);
  const [data, setData] = useState('loading');
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isProgress, setIsProgress] = useState(false);
  const [resendMailId, setResendMailId] = useState(null);
  const [resendModal, setResendModal] = useState(false);

  const {
    data: guestReportData,
    loading: reportsLoading,
    error: reportsError,
    refetch: refetchGuestReports,
  } = useQuery(GUEST_REPORTS, {
    onCompleted: (data) => {},
    onError: (e) => {
      console.log(e);
    },
  });

  const history = useHistory();

  useEffect(() => {
    if (guestReportData) {
      if (guestReportData.reports.totalCount === 0) {
        setData('noData');
      } else {
        setData('data');
        setReports(guestReportData.reports.edges);
      }
    }
  }, [guestReportData]);

  const [reportResendMail, { data: reportResendData }] = useMutation(
    RESEND_REPORT,
    {
      onCompleted: (data) => {
        if (data.resendReport.errors === null) {
          setResendModal(!resendModal);
          toast(data.resendReport.message, { type: 'success' });
          setIsProgress(false);
        } else {
          toast(data.resendReport.message, { type: 'error' });
        }
      },
      onError: (e) => {
        console.log(e);
        toast(e, { type: 'error' });
      }
    }
  );

  const [reportMultiDelete] = useMutation(REPORTS_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.reportMultiDelete.message, { type: 'success' });
      setIsProgress(false);
    },
    refetchQueries: [GUEST_REPORTS],
    onError: (e) => {
      console.log(e);
    },
  });

  const [updateReportTitle] = useMutation(UPDATE_REPORT_TITLE, {
    onCompleted: (data) => {
      toast(data.updateReportTitle.message, { type: 'success' });
    },
    onError: (e) => {
      console.log(e);
    },
  });
  
  const resendMail = (id = null) => {
    setResendMailId(parseInt(id));
    setResendModal(true);
  };

  const handleResendMail = async () => {
    setIsProgress(true);
    await reportResendMail({ variables: { id: resendMailId } });
  };

  const onResendPopup = () => {
    if (resendMailId) {
      setResendModal(!resendModal);
    }
  };

  const handleDeleteAll = async () => {
    setIsProgress(true);
    await reportMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

  const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

  const handleTitleInput = (e, reportId) => {
    updateReportTitle({
      variables: {
        id: Number(reportId),
        title: e,
      },
    });
  };

  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      if (key.original) {
        isSelectedIds.push(parseInt(key.original.node.id));
      }
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);


  const [sidebarWidth, setSidebarWidth] = useState('1150px');
  const [sidebarState, setSidebarState] = useState(false);
  const [showInSidebar, setShowInSidebar] = useState('');
  const [reportId, setReportId] = useState([]);
  const [draftTitle, setDraftTitle] = useState('');

  const showSendList = (
    <SendDraftReport
      reportId={reportId}
      draftTitle={draftTitle}
      sidebarToggle={() => {
        setSidebarState(!sidebarState);
        refetchGuestReports();
      }}
      {...props}
    />
  );

  useEffect(() => {
    if (sidebarState) {
      setSidebarWidth('1150px');
      setShowInSidebar(showSendList);
    } else {
      setShowInSidebar('');
      setSidebarWidth('0px');
    }
  }, [sidebarState]);

  const handleReportProperty = (value, title) => {
    setReportId(parseInt(value));
    setDraftTitle(title);
    setSidebarState(true);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Client Name',
        accessor: 'node.client.name',
        Cell: ({ value, row }) => <div>{value || row.original.node.clientName || row.original.node.name} {row.original.node.client ? row.original.node.client.discardedAt ? <Badge style={{backgroundColor: '#FEAC61'}} pill>Archived</Badge> : null : row.original.node.isDraft && <Badge style={{backgroundColor: '#FE9090'}} pill>Draft</Badge> }</div>,
      },
			{
        Header: 'Action',
        accessor: 'node.id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div>
            <UncontrolledButtonDropdown>
              <DropdownToggle color='link' className='bg-transparent border-0 shadow-none p-0 text-dark' aria-label='Action'>
                <Icon icon='ellipsis-v' />
              </DropdownToggle>
              <DropdownMenu>
                {
                  row.original.node.isDraft ?
                    <>
                      <DropdownItem onClick={() => handleReportProperty(value, row.original.node.title)}>
                        Edit
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => handleReportProperty(value, row.original.node.title)}>
                        Send Report
                      </DropdownItem>
                    </>
                  :
                    <DropdownItem onClick={() => resendMail(value)}>
                      Resend Email
                    </DropdownItem>
                }
                <DropdownItem divider />
                <DropdownItem onClick={() => handleSingleDelete(value)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
      {
        Header: 'Title',
        accessor: 'node.title',
        Cell: ({ value, row }) => {
          const [title, setTitle] = useState(value || '');
          useEffect(() => {
            setTitle(value || '');
          }, [value, row]);
          const handleTitleChange = (e) => {
            setTitle(e.target.value);
          };
          const handleTitleBlur = () => {
            if (title.trim() === '') {
              toast.error('Title is required');
              setTitle(value || '');
            } else {
              handleTitleInput(title, row.original.node.id);
            }
          };
          return (
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              style={{ width: '100%', border: 'none', borderRadius: '6px', padding: '4px', outline: 'none' }}
            />
          );
        },
      },
      {
        Header: 'URL',
        accessor: 'node.reportCode',
        Cell: ({ value, row }) => (
          <div>
            {value ? (
              <a
                target='_blank'
                href={config.url.WEB_DOMAIN + 'recommendations?code=' + value}
              >
                Click here
              </a>
            ) : (
              '-'
            )}
          </div>
        ),
      },
      {
        Header: 'Updated At',
        accessor: 'node.updatedAt',
        Cell: ({ value, row }) => (
          <div>{value ? formatTimeStamp(value) : '-'}</div>
        ),
      },
    ],
    []
  );
  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };
  const formatTimeStamp = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return new Intl.DateTimeFormat('default', options).format(
      Date.parse(dateString)
    );
  };

  return (
    <>
      {reportsLoading && <Spinner />}
      <SidebarRight
        sidebarState={sidebarState}
        sidebarToggle={() => {
          setSidebarState(!sidebarState);
        }}
        sidebarContent={showInSidebar}
        sidebarWidth={sidebarWidth}
        sidebarButton={false}
      />
      <Modal isOpen={resendModal} toggle={onResendPopup}>
        <ModalHeader toggle={onResendPopup}>Confirm Resend</ModalHeader>
        <ModalBody>Are you sure you want to resend this List?</ModalBody>
        <ModalFooter>
          <Button
            color='success'
            disabled={isProgress}
            onClick={handleResendMail}
          >
            Yes
          </Button>
          <Button color='primary' onClick={onResendPopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} recommendation
          {isCheckedId.length > 1 ? 's' : ''}?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color='success' onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Row className='mt-md-4'>
        <Col md='12'>
          {data === 'data' ? (
            <RTable
              columns={columns}
              data={reports}
              filterable
              addons={
                <Button
                  color='red'
                  onClick={onAllDeletePopup}
                  disabled={isCheckedId.length > 0 ? false : true}
                  className='button-dashboard float-right'
                >
                  <Icon icon='trash-alt' className='mr-2' />
                  Delete
                </Button>
              }
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
            />
          ) : (
            <>
              {data === 'noData' ? (
                <div className='text-center' style={{ margin: '35vh 0' }}>
                  <h4>
                    You haven't sent property recommendations to any clients.
                  </h4>
                  <Button
                    onClick={() => history.push('/properties')}
                    color='success'
                    className='button-dashboard mt-2'
                  >
                    Send property recommendations to a client now{' '}
                    <Icon icon='chevron-right' className='ml-2' />
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
