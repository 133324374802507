import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap';
import { Icon } from '@assets';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MY_PROFILE, UPDATE_PROFILE } from '@api';
import { useQuery, useMutation } from '@apollo/client';
import Spinner from '../Spinner';
import config from '@config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '@sparklib';

export default function UserProfile() {
  const [user, setUser] = useState([]);
  const [logo, setLogo] = useState();
  const [brokerageInfo, setBrokerageInfo] = useState();
  const [avatar, setAvatar] = useState();
  const [deleteLogo, setDeleteLogo] = useState('false');
  const [deleteAvatar, setDeleteAvatar] = useState('false');
  const [deleteBrokerageInfo, setDeleteBrokerageInfo] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [is2FA, setIs2FA] = useState(true);

  const logoUpload = useRef();

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    logo && URL.revokeObjectURL(logo.preview);
  }, [logo]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    avatar && URL.revokeObjectURL(avatar.preview);
  }, [avatar]);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const [userProfileUpdate, { data, loading, error }] = useMutation(
    UPDATE_PROFILE,
    {
      context: { hasUpload: true },
      onCompleted: (data) => {
        if (
          data.userProfileUpdate.errors &&
          data.userProfileUpdate.errors.length > 0
        ) {
          data.userProfileUpdate.errors.map((err) =>
            toast(err.replace('Url name ', ''), { type: 'error' })
          );
        } else {
          profileRefetch();
          toast(data.userProfileUpdate.message, { type: 'success' });
        }
      },
      onError: (e) => {
        console.log(e, 'updated profile ON ERROR');
      },
    }
  );

  const {
    data: profileData,
    loading: ProfileLoading,
    refetch: profileRefetch,
  } = useQuery(MY_PROFILE, {
    onCompleted: (data) => {
      if (data.myProfile) {
        setUser(data.myProfile);
        setIs2FA(data.myProfile.otpRequiredForLogin);
      } else {
        toast('Profile not found', { type: 'error' });
      }
    },
    onError: (error) => {
      console.log(error + '>>>>> ERROR >>>>>');
      toast('Profile not found', { type: 'error' });
    },
  });
  useEffect(() => {
    profileData && setUser(profileData.myProfile);
  }, [profileData]);

  const onSubmit = (data, e) => {
    userProfileUpdate({
      variables: {
        email: user.email,
        phoneNo: user.phoneNo,
        firstName: user.firstName,
        lastName: user.lastName,
        brokerageName: user.brokerageName,
        deleteLogo: deleteLogo === 'true' ? true : false,
        deleteAvatar: deleteAvatar === 'true' ? true : false,
        deleteBrokerageInfo: deleteBrokerageInfo,
        brokerageInfoUrl: user.brokerageInfoUrl ? user.brokerageInfoUrl : null,
        logo: logo,
        avatar: avatar,
        brokerageInfo: brokerageInfo,
        street1: user.street1,
        street2: user.street2,
        cityName: user.cityName,
        stateName: user.stateName,
        zip: user.zip,
        otpRequiredForLogin: is2FA,
        urlName: user.urlName.toLowerCase().trim(),
        facebook: user.facebook,
        instagram: user.instagram,
        linkedin: user.linkedin,
        twitter: user.twitter,
        calendly: user.calendly,
        tiktok: user.tiktok,
        youtube: user.youtube,
        pinterest: user.pinterest,
        whatsapp: user.whatsapp,
        wechat: user.wechat,
        googlePage: user.googlePage,
        personalWebsite: user.personalWebsite,
        aboutMe: user.aboutMe,
      },
    });
    // console.log(data, "Profile Update Submission")
  };

  function changeUserDetail(e) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  function toggleShowChangePassword() {
    setShowChangePassword(!showChangePassword);
  }

  const isNumericInput = (event) => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };

  const enforceFormat = (event) => {
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
    }
  };

  const formatToPhone = (event) => {
    if (isModifierKey(event)) {
      return;
    }

    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      event.target.value = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      event.target.value = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      event.target.value = `(${areaCode}`;
    }
  };

  const justFomatePhoneNo = (str) => {
    var input = str.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    var areaCode = input.substring(0, 3);
    var middle = input.substring(3, 6);
    var last = input.substring(6, 10);
    return `(${areaCode}) ${middle} - ${last}`;
  };

  const inputElement = document.getElementById('phoneNumber');
  if (inputElement != null) {
    inputElement.addEventListener('keydown', enforceFormat);
    inputElement.addEventListener('keyup', formatToPhone);
  }
  if (user?.phoneNo && user.phoneNo.length < 16) {
    var formatedPhoneNo = justFomatePhoneNo(user.phoneNo);
    document.getElementById('phoneNumber').value = formatedPhoneNo;
    var phoneInput = document.getElementById('phoneNumber');
    if (formatedPhoneNo?.length === 16) {
      setUser({ ...user, phoneNo: formatedPhoneNo });
    }
  }

  return (
    <>
      {/* <UpdatePassword
				parentRef={toggleShowChangePassword}
				displayModal={showChangePassword}
				token={localStorage.getItem('auth_token')}
			/>  */}
      <div className='text-left mt-5'>
        {ProfileLoading && <Spinner />}
        {/* <Row className='mt-2'> */}
        {/* <Col md='12' className='mb-3 text-left'> */}
        <h3>My Profile</h3>
        {/* </Col> */}
        {/* <Col md='4' className='mt-md-3 mb-3'>
							<Button color='primary' className='button-dashboard' onClick={toggleShowChangePassword}>
								Change Password
							</Button>
						</Col> */}

        {/* <Col
							lg={{ size: 6, offset: 3 }}
							md={{ size: 8, offset: 2 }}
							className='text-left mt-3 mt-md-3'
						> */}
        <Form className='form' onSubmit={handleSubmit(onSubmit)}>
          <Row className='mt-4'>
            <Col md='3'>
              <FormGroup className='inputs-icons'>
                <Label for='firstName'>First Name</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='user' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='firstName'
                    onChange={changeUserDetail}
                    value={user.firstName}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>
                {_.get('firstName.type', errors) === 'required' && (
                  <p className='input-error'>* First Name required.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='3'>
              <FormGroup className='inputs-icons'>
                <Label for='lastName'>Last Name</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='user' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='lastName'
                    onChange={changeUserDetail}
                    value={user.lastName}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>
                {_.get('lastName.type', errors) === 'required' && (
                  <p className='input-error'>* Last Name required.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <Label for='lastName'>Brokerage Name</Label>
                <Input
                  type='text'
                  name='brokerageName'
                  onChange={changeUserDetail}
                  value={user.brokerageName}
                  autoComplete='off'
                  // disabled={user.isAgent ? true : false}
                  innerRef={register({
                    required: true,
                  })}
                />
                {_.get('brokerageName.type', errors) === 'required' && (
                  <p className='input-error'>* Brokerage Name required.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='clientEmail'>Email</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='envelope' />
                  </InputGroupText>
                  <Input
                    type='text'
                    placeholder='Email'
                    name='email'
                    onChange={changeUserDetail}
                    value={user.email}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                    })}
                  />
                </InputGroup>
                {_.get('email.type', errors) === 'required' && (
                  <p className='input-error'>* Email required.</p>
                )}
                {_.get('email.type', errors) === 'pattern' && (
                  <p className='input-error'>* Invalid Email.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='clientPhone'>Phone</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='phone' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='phoneNo'
                    id='phoneNumber'
                    onChange={(e) => {
                      if (e.target.value?.length <= 16) changeUserDetail(e);
                    }}
                    value={user.phoneNo}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>
                {_.get('phoneNo.type', errors) === 'required' && (
                  <p className='input-error'>* Phone required.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='street1'>Broker Address 1</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='map-marker' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='street1'
                    onChange={changeUserDetail}
                    value={user.street1}
                    autoComplete='off'
                    innerRef={register({
                      required: false,
                    })}
                  />
                </InputGroup>
                {_.get('street1.type', errors) === 'required' && (
                  <p className='input-error'>* Broker Address 1 required.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='street2'>Broker Address 2</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='map-marker' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='street2'
                    onChange={changeUserDetail}
                    value={user.street2}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup className='inputs-icons'>
                <Label for='cityName'>City</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='city' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='cityName'
                    onChange={changeUserDetail}
                    value={user.cityName}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>
                {_.get('cityName.type', errors) === 'required' && (
                  <p className='input-error'>* Market Name required.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup className='inputs-icons'>
                <Label for='stateName'>State</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='map' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='stateName'
                    onChange={changeUserDetail}
                    value={user.stateName}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>
                {_.get('stateName.type', errors) === 'required' && (
                  <p className='input-error'>* State Name required.</p>
                )}
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup className='inputs-icons'>
                <Label for='stateName'>Zip Code</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='barcode' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='zip'
                    onChange={changeUserDetail}
                    value={user.zip}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>
                {_.get('zip.type', errors) === 'required' && (
                  <p className='input-error'>* Zip Code required.</p>
                )}
              </FormGroup>
            </Col>
            {/* <Col md='4'>
										<FormGroup>
											<Label check>Secure Login</Label>
											<InputGroup className='align-items-center pt-2'>
												<Label check>Two Factor Authentication </Label>&nbsp;&nbsp;
												<ToggleButton
													selected={is2FA}
													firstOption='ON'
													secondOption='OFF'
													width='85px'
													rounded
													toggleSelected={() => {
														setIs2FA(!is2FA);
													}}
												/>
											</InputGroup>
										</FormGroup>
									</Col> */}
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='urlName'>Apartment Inquiry Link</Label>
                <InputGroup>
                  <span style={{ paddingTop: '7px', fontSize: 'medium' }}>
                    {' '}
                    {config.url.WEB_DOMAIN}inquiry/
                  </span>
                  <Input
                    className='ml-1 rounded p-0 pl-2'
                    type='text'
                    name='urlName'
                    onChange={changeUserDetail}
                    value={user.urlName}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      pattern: /^[a-zA-Z0-9-]+$/,
                    })}
                  />
                  <Icon
                    icon='copy'
                    size='lg'
                    color='blue'
                    className='ml-2 mt-2 cursor-pointer'
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${config.url.WEB_DOMAIN}inquiry/${user.urlName}`
                      )
                    }
                  />
                </InputGroup>
                {_.get('urlName.type', errors) === 'required' && (
                  <p className='input-error'>* URL required.</p>
                )}
                {_.get('urlName.type', errors) === 'pattern' && (
                  <p className='input-error'>
                    * URL name must be alpha numeric with/without (-), Space not
                    allowed
                  </p>
                )}
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='facebook'>Facebook Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='facebook'
                    onChange={changeUserDetail}
                    value={user.facebook}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='instagram'>Instagram Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='instagram'
                    onChange={changeUserDetail}
                    value={user.instagram}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='linkedin'>LinkedIn Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'linkedin']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='linkedin'
                    onChange={changeUserDetail}
                    value={user.linkedin}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='twitter'>Twitter Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='twitter'
                    onChange={changeUserDetail}
                    value={user.twitter}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='calendly'>Calendly Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fa', 'calendar-alt']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='calendly'
                    onChange={changeUserDetail}
                    value={user.calendly}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='calendly'>TikTok Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'tiktok']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='tiktok'
                    onChange={changeUserDetail}
                    value={user.tiktok}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='youtube'>YouTube Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='youtube'
                    onChange={changeUserDetail}
                    value={user.youtube}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='whatsapp'>Whatsapp Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='whatsapp'
                    onChange={changeUserDetail}
                    value={user.whatsapp}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='pinterest'>Pinterest Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'pinterest']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='pinterest'
                    onChange={changeUserDetail}
                    value={user.pinterest}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='wechat'>Wechat Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <FontAwesomeIcon icon={['fab', 'weixin']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='wechat'
                    onChange={changeUserDetail}
                    value={user.wechat}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='googlePage'>Google Page Link</Label>
                <InputGroup>
                  <InputGroupText>
                    {/* <Icon icon='google' /> */}
                    <FontAwesomeIcon icon={['fab', 'google']} />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='googlePage'
                    onChange={changeUserDetail}
                    value={user.googlePage}
                    autoComplete='off'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className='inputs-icons'>
                <Label for='personalWebsite'>Personal Website Link</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='globe' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='personalWebsite'
                    onChange={changeUserDetail}
                    value={user.personalWebsite}
                    autoComplete='off'
                    innerRef={register({
                      pattern: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?\/?$/,
                    })}
                  />
                </InputGroup>
                {_.get('personalWebsite.type', errors) === 'pattern' && (
                  <p className='input-error'>
                    * URL name must be start with or without https, http or www
                  </p>
                )}
              </FormGroup>
            </Col>
            {user.apiKey && (
              <Col md='12'>
                <FormGroup className='inputs-icons'>
                  <Label for='apiKey'>API Key</Label>
                  <InputGroup>
                    <InputGroupText className='pointer'>
                      <Icon
                        icon='copy'
                        onClick={() =>
                          navigator.clipboard.writeText(user.apiKey)
                        }
                      />
                    </InputGroupText>
                    <Input type='text' name='apiKey' value={user.apiKey} />
                  </InputGroup>
                  {user.apiKeyExpiredAt && (
                    <p className='error-msg'>
                      Your access to Spark API key has revoked{' '}
                    </p>
                  )}
                </FormGroup>
              </Col>
            )}
            <Col md='12'>
              <Row>
                <Col md='6'>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='Avatar'>Profile Picture</Label>
                        <InputGroup>
                          {((user.avatarThumbUrl && deleteAvatar !== 'true') ||
                            avatar) && (
                            <>
                              <img
                                src={
                                  avatar ? avatar.preview : user.avatarThumbUrl
                                }
                                alt={user.first_name}
                                className='thumb mr-2'
                              />
                              <Icon
                                icon='times'
                                className='remove-item my-profile'
                                onClick={() => {
                                  setDeleteAvatar('true');
                                  setAvatar();
                                }}
                              />
                              <Input
                                type='hidden'
                                name='deleteAvatar'
                                value={deleteAvatar}
                              />
                            </>
                          )}
                          <Dropzone
                            name='avatarDropZone'
                            accept='image/jpeg, image/png'
                            maxFiles={1}
                            multiple={false}
                            onDrop={(acceptedFiles) => {
                              acceptedFiles.length > 0 &&
                                setAvatar(
                                  // files.map((file) =>
                                  Object.assign(acceptedFiles[0], {
                                    preview: URL.createObjectURL(
                                      acceptedFiles[0]
                                    ),
                                  })
                                );
                            }}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragAccept,
                              isDragReject,
                            }) => {
                              const style = useMemo(
                                () => ({
                                  ...baseStyle,
                                  ...(isDragActive ? activeStyle : {}),
                                  ...(isDragAccept ? acceptStyle : {}),
                                  ...(isDragReject ? rejectStyle : {}),
                                }),
                                [isDragActive, isDragReject, isDragAccept]
                              );
                              return (
                                <span className='flex-grow'>
                                  <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <div className='text-center w-100'>
                                      Drag 'n drop the avatar here, <br />
                                      or click to select the avatar <br />
                                      <small>
                                        <em>
                                          (only jpeg or png files supported,
                                          recommended dimensions: 500 X 500 px)
                                        </em>
                                      </small>
                                    </div>
                                  </div>
                                </span>
                              );
                            }}
                          </Dropzone>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='Logo'>Logo</Label>

                        <InputGroup>
                          {((user.logoThumbUrl && deleteLogo !== 'true') ||
                            logo) && (
                            <>
                              <img
                                src={logo ? logo.preview : user.logoThumbUrl}
                                alt={user.first_name}
                                className='thumb mr-2'
                              />
                              {user.isAgent !== true && (
                                <Icon
                                  icon='times'
                                  className='remove-item my-profile'
                                  onClick={() => {
                                    setDeleteLogo('true');
                                    setLogo();
                                  }}
                                />
                              )}
                              <Input
                                type='hidden'
                                name='deleteLogo'
                                value={deleteLogo}
                              />
                            </>
                          )}
                          <Dropzone
                            name='logoDropZone'
                            accept='image/jpeg, image/png'
                            maxFiles={1}
                            disabled={user.isAgent ? true : false}
                            multiple={false}
                            onDrop={(acceptedFiles) => {
                              acceptedFiles.length > 0 &&
                                setLogo(
                                  // files.map((file) =>
                                  Object.assign(acceptedFiles[0], {
                                    preview: URL.createObjectURL(
                                      acceptedFiles[0]
                                    ),
                                  })
                                );
                            }}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragAccept,
                              isDragReject,
                            }) => {
                              const style = useMemo(
                                () => ({
                                  ...baseStyle,
                                  ...(isDragActive ? activeStyle : {}),
                                  ...(isDragAccept ? acceptStyle : {}),
                                  ...(isDragReject ? rejectStyle : {}),
                                }),
                                [isDragActive, isDragReject, isDragAccept]
                              );
                              return (
                                <span className='flex-grow'>
                                  <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <div className='text-center w-100'>
                                      Drag 'n drop the logo here, <br />
                                      or click to select the logo <br />
                                      <small>
                                        <em>
                                          (only jpeg or png files supported,
                                          recommended dimensions: 500 X 250 px)
                                        </em>
                                      </small>
                                    </div>
                                  </div>
                                </span>
                              );
                            }}
                          </Dropzone>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='Logo'>
                          TREC Information About Brokerage Services (Texas
                          only).
                        </Label>

                        <InputGroup>
                          {((user.brokerageInfo && !deleteBrokerageInfo) ||
                            brokerageInfo) && (
                            <>
                              <iframe
                                src={
                                  brokerageInfo
                                    ? brokerageInfo.preview
                                    : user.brokerageInfo
                                }
                                // alt={user.brokerageInfo}
                                className='thumb mr-2'
                              />
                              <Icon
                                icon='times'
                                className='remove-item my-profile'
                                onClick={() => {
                                  setDeleteBrokerageInfo(true);
                                  setBrokerageInfo();
                                }}
                              />
                              <Input
                                type='hidden'
                                name='deleteBrokerageInfo'
                                value={deleteBrokerageInfo}
                              />
                            </>
                          )}
                          <Dropzone
                            name='brokerageInfoDropZone'
                            input='*.pdf'
                            maxFiles={1}
                            multiple={false}
                            onDrop={(acceptedFiles) => {
                              acceptedFiles.length > 0 &&
                                setBrokerageInfo(
                                  // files.map((file) =>
                                  Object.assign(acceptedFiles[0], {
                                    preview: URL.createObjectURL(
                                      acceptedFiles[0]
                                    ),
                                  })
                                );
                            }}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragAccept,
                              isDragReject,
                            }) => {
                              const style = useMemo(
                                () => ({
                                  ...baseStyle,
                                  ...(isDragActive ? activeStyle : {}),
                                  ...(isDragAccept ? acceptStyle : {}),
                                  ...(isDragReject ? rejectStyle : {}),
                                }),
                                [isDragActive, isDragReject, isDragAccept]
                              );
                              return (
                                <span className='flex-grow'>
                                  <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <div className='text-center w-100'>
                                      Drag 'n drop the brokerage info here,{' '}
                                      <br />
                                      or click to select the brokerage info{' '}
                                      <br />
                                      <small>
                                        <em>(only PDF supported)</em>
                                      </small>
                                    </div>
                                  </div>
                                </span>
                              );
                            }}
                          </Dropzone>
                        </InputGroup>
                        <p
                          style={{
                            textAlign: 'center',
                            padding: '2px 0px',
                            marginBottom: 0,
                          }}
                        >
                          <small>OR</small>
                        </p>
                        <Label for='brokerageInfoUrl'>Add Hyperlink</Label>
                        <InputGroup>
                          <Input
                            type='text'
                            name='brokerageInfoUrl'
                            autoComplete='off'
                            onChange={changeUserDetail}
                            defaultValue={user.brokerageInfoUrl}
                            value={user.brokerageInfoUrl}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md='6'>
                  <FormGroup className='inputs-icons'>
                    <Label for='aboutMe'>About</Label>
                    <InputGroup>
                      <Input
                        type='textarea'
                        name='aboutMe'
                        rows={18}
                        autoComplete='off'
                        onChange={changeUserDetail}
                        value={user.aboutMe}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md='12' className='text-center mt-4'>
              <Button color='primary' className='button-dashboard'>
                Update
              </Button>
            </Col>
          </Row>
        </Form>
        {/* </Col> */}
        {/* </Row> */}
      </div>
    </>
  );
}
